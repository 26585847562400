import create from 'zustand';
import {PerspectiveCamera} from 'three';
import {logHelper, tLogStyled} from 'utils/Logger';

type CameraStoreProps = {
  cameraBrain: PerspectiveCamera | null;
  setCameraBrain: (cam: PerspectiveCamera) => void;

  rawFov: number;
  setRawFov: (fov: number) => void;

  virtualCameras: PerspectiveCamera[] | [];
  setVirtualCameras: (cams: PerspectiveCamera[]) => void;

  // virtualCameraTargets: Object3D[] | [];
  // setVirtualCameraTargets: (targets: Object3D[]) => void;

  activeVirtualCamera: PerspectiveCamera | null;
  setActiveVirtualCamera: (state?: string) => void;
};

export const useCameraStore = create<CameraStoreProps>((set, get) => ({
  cameraBrain: null,
  setCameraBrain: (cam) => set({cameraBrain: cam}),

  rawFov: 10,
  setRawFov: (fov) => set({rawFov: fov}),

  virtualCameras: [],
  setVirtualCameras: (cams) => set({virtualCameras: cams}),
  // virtualCameraTargets: [],
  // setVirtualCameraTargets: (targets) => set({virtualCameraTargets: targets}),
  activeVirtualCamera: null,
  setActiveVirtualCamera: (state) => {
    const activeVirtualCamera = get().virtualCameras.find(camera => {
      const activeStates: string[] = camera.userData.tags.activeStates.split('&');
      const invertStates: boolean = camera.userData.tags.invertStates;
      const isActive = activeStates.some(activeState => activeState === state);
      // TODO add priority ??
      return invertStates ? !isActive : isActive;
    }) || null;

    if (get().activeVirtualCamera === activeVirtualCamera) return;

    tLogStyled(`[CameraStore] activeVirtualCamera ${activeVirtualCamera?.name}` /* [${activeVirtualCamera?.uuid}]`*/, logHelper.processing); // DEBUG
    set({activeVirtualCamera: activeVirtualCamera});
  },
}));