import create from 'zustand';

export type SizeClass = {
  width: number;
  height: number;
  sizeClass: string;
  portrait: boolean;
  fovMultiplier: number;
  setSizeClass: () => void;
}

const useSizeClassStore = create<SizeClass>(set => ({
  width: 0,
  height: 0,
  sizeClass: '',
  portrait: false,
  fovMultiplier: 1,
  setSizeClass: () => {
    const portrait = window.innerHeight > window.innerWidth;
    const sizeClass = getSizeClass();
    set({
      width: screen.width,
      height: screen.height,
      portrait: portrait,
      sizeClass: sizeClass,
      fovMultiplier: getFovMultiplier(portrait, sizeClass)
    });
  }
}));

// const getSizeClass = (width: number): string => {
//   // if (width >= 1600) return 'desktop';
//   if (width >= 767 && width < 1600) return 'tablet';
//   if (width < 767) return 'mobile';
//
//   return 'desktop';
// };

const getFovMultiplier = (portrait: boolean, sizeClass: string): number => {
  switch (sizeClass) {
    case 'tablet' :
      return portrait ? 1.5 : 1;
    case 'mobile':
      return portrait ? 2 : 1.5;
    default:
      return portrait ? 1.25 : 1;
  }
};

const getSizeClass = (): string => {
  const area = screen.width * screen.height;

  // desktop >= 1_500_000
  // tablet limit < 1_500_000
  // mobile limit < 500_000

  let sizeClass = 'desktop';
  if (area >= 500_00 && area < 1_500_000) sizeClass = 'tablet';
  if (area < 500_000) sizeClass = 'mobile';

  return sizeClass;
};

export {useSizeClassStore};
