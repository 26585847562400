import React, {useEffect, useRef} from 'react';
import {useSizeClassStore} from 'stores/useSizeClassStore';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

import 'components/Logo/Logo.scss';
import logo_ceva from 'assets/images/logo_ceva.png';

const Logo: React.FC = () => {
  const logoContainerRef = useRef<HTMLDivElement>(null);
  const sizeClass = useSizeClassStore(state => state.sizeClass);
  const {currentFSMState} = useFSMStore(state => ({
    currentFSMState: state.currentFSMState
  }));

  // State Management
  useEffect(() => {
    if (currentFSMState === FSMStates.loading ||
      currentFSMState === FSMStates.loaded ||
      currentFSMState === FSMStates.mainMenu) {
      addClass(logoContainerRef, 'hidden');
    } else {
      removeClass(logoContainerRef, 'hidden');
    }
  }, [currentFSMState]);

  return (
    <div ref={logoContainerRef} className="logo-ceva">
      <img className={sizeClass} src={logo_ceva} alt="logo" />
    </div>
  );
};

export default Logo;
