import React from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {useTranslation} from 'react-i18next';

import './LoadingError.scss';

const LoadingError: React.FC = () => {
  const {t} = useTranslation();
  const loadingError = useSceneStore(state => state.loadingError);

  return (
    <div className="loading-error">
      <div>
        {t('landing_page.loading_error_message')}
      </div>
      <div>
        {loadingError?.message}
      </div>
    </div>
  );
};

export default LoadingError;
