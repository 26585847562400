import {useCallback, useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {
  Color,
  FrontSide,
  Material,
  Mesh,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera
} from 'three';
import {Object3DTypedArray, useSceneStore} from 'stores/useSceneStore';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import {useCameraStore} from 'stores/useCameraStore';
import {useThree} from '@react-three/fiber';

export const useParseGLTF = (scene: Object3D): void => {
  // const [sortedObjects, setSortedObjects] = useState<Object3DTypedArray>();
  const setFSMState = useFSMStore(state => state.setFSMState);
  const {/*sortedObjects,*/ setSortedObjects} = useSceneStore(state => ({
    // sortedObjects: state.sortedObjects,
    setSortedObjects: state.setSortedObjects
  }));
  const {setCameraBrain, setVirtualCameras} = useCameraStore(state => ({
    setCameraBrain: state.setCameraBrain,
    setVirtualCameras: state.setVirtualCameras
  }));
  const camera = useThree(state => state.camera);

  //#region Plumbing

  const processCameras = useCallback((sortedObjects: Object3DTypedArray): void => {
      if (!sortedObjects || Object.keys(sortedObjects).length === 0) return /*false*/;

      camera.far = 100;
      camera.near = 0.05;
      setCameraBrain(camera as PerspectiveCamera); // r3f default camera

      const virtualCameras = sortedObjects['camera_virtual'] as PerspectiveCamera[] || [];
      setCameraTargets(virtualCameras, scene);
      setVirtualCameras(virtualCameras);

    }, [camera, scene, setCameraBrain, setVirtualCameras]);

  const setShadowProps = useCallback(() => {
      scene.traverse((obj: Object3D) => {
        // @ts-ignore
        if (obj.isMesh && !obj.userData.tags?.noShadows) {
          obj.castShadow = true;
          obj.receiveShadow = true;
        }
      });
    }, [scene]);

  const processMaterials = useCallback(() => {
      scene.traverse((obj: Object3D) => {
        // @ts-ignore
        if (obj.isMesh) {
          const mesh = obj as Mesh;
          const material = mesh.material as Material;

          // material.side = FrontSide;

          if (material.name === 'background') {// TODO find another solution to differentiate ground object
            material.dithering = true;
          }

          if (material.name === 'vitrage' || material.name === 'palstic clear') {
            (material as MeshStandardMaterial).roughness = 0.05;
            (material as MeshStandardMaterial).metalness = .9;
          }

          if (material.name === 'plastic translu') {
            (material as MeshStandardMaterial).roughness = 0.5;
            (material as MeshStandardMaterial).metalness = .9;
          }
        }
      });
    }, [scene]);

  //#endregion

  // Sort objects when scene changes
  useEffect(() => {
    if (scene) {
      tLogStyled('[useParseGLTF] GLTF Scene ', logHelper.subdued, scene); // DEBUG

      // TODO uncomment to add shadows
      // setShadowProps();

      processMaterials();

      const _sortedObjects = sortObjectsByType(scene);
      setSortedObjects(_sortedObjects);

      if (_sortedObjects && Object.keys(_sortedObjects).length > 0) {
        tLogStyled('[useParseGLTF] Sorted Objects ', logHelper.subdued, _sortedObjects); // DEBUG
        processCameras(_sortedObjects);
      }

      setFSMState(FSMStates.loaded); // TODO: move where parsing is done
    }
  }, [processCameras, scene, setFSMState, setSortedObjects]);

};

/**
 * Sort GLTF Scene children by their type.
 * <p>The `type` is read from `.userData.tags` of the child `Object3D`</p>
 */
const sortObjectsByType = (scene: Object3D): Object3DTypedArray => {
  const result: { [key: string]: Object3D[] } = {}; // :Object3DTypedArray
  const getTypeOf = (obj: Object3D) => obj.userData.tags?.type;
  const firstItemOf = (type: string) => !result[type];
  const initializePropertyOf = (type: string) => result[type] = [];

  scene.traverse(obj => {
    const types = getTypeOf(obj);
    if (types) {
      const splitTypes = types.split('&');
      splitTypes.forEach((type: string) => {
        if (firstItemOf(type)) initializePropertyOf(type);
        result[type].push(obj);
      });
    }
  });

  return result;
};

const setCameraTargets = (cameras: Object3D[], scene: Object3D): Object3D[] => {
  // If cameraTarget is set find this object by name
  // If not set then try to find object with the name Camera.name + "Target"
  cameras.forEach(cam => {
    const targetName = cam.userData.tags?.cameraTarget?.replace('.', '') || cam.name + 'Target';

    scene.traverse(obj => {
      if (obj.name === targetName) {
        cam.userData.tags.cameraTarget = obj; // replace target name by its object
      }
    });
  });

  return cameras;
};
