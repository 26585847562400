import React from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {useTranslation} from 'react-i18next';
// @ts-expect-error no declaration file...
import {CircleProgress} from 'react-gradient-progress';

import './LoadingFeedback.scss';

const LoadingFeedback: React.FC = () => {
  const {t} = useTranslation();
  const percentageLoaded = useSceneStore(state => state.percentageLoaded);

  return (
    <div className="loading-feedback">
      <div className="circle">
        <CircleProgress percentage={Math.round(percentageLoaded)} width={100} strokeWidth={8} />
      </div>
      <div className="text">
        {t('landing_page.loading_message')}
      </div>
    </div>
  );
};

export default LoadingFeedback;