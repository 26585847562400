import {Object3D, Vector3} from 'three';
import {useFSMStore} from 'stores/useFSMStore';
import {useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {useFrame} from '@react-three/fiber';

export const useHandleObjectVisibility = (scene: Object3D): void => {
  const currentFSMState = useFSMStore(state => state.currentFSMState);

  useEffect(() => {
    if (scene) {
      const isValidRecursive = (validStates: string[], currentFSMState: string): boolean => {
        if (!validStates || validStates.length < 1 || !currentFSMState) return false;

        if (validStates.includes(currentFSMState)) return true;
        else {
          const parent = currentFSMState.split('.').slice(0, -1); // remove last element
          return isValidRecursive(validStates, parent.join('.'));
        }
        return false;
      };

      tLogStyled('[useHandleObjectVisibility] Setting objects visibility...', logHelper.processing);
      scene.traverse(node => {
        const activeStates: string[] = node.userData.tags?.activeStates?.split('&');
        const invertStates: boolean = node.userData.tags?.invertStates;
        if (activeStates !== undefined || invertStates !== undefined) {
          const isActive = isValidRecursive(activeStates, currentFSMState!);
          const isVisible = invertStates ? !isActive : !!isActive;
          node.traverse(child => {
            child.visible = isVisible;
          });
        }
      });
    }
  }, [currentFSMState, scene]);

  // TODO hack to hide chicks when below lowest crate
  const v3 = new Vector3();
  useFrame(({scene}) => {
    scene.traverse(node => {
      if (node.name.toLocaleLowerCase().includes('poussin') && node.type === 'Mesh') {
        node.visible = node.getWorldPosition(v3).y >= 0.45;
      }
    });
  });
};