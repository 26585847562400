import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import {useLoadAsset} from 'hooks/useLoadAsset';
import {Asset} from 'stores/useConfigStore';
import {useParseGLTF} from 'hooks/useParseGLTF';
import {useCameraController} from 'hooks/useCameraController';
import {useSceneBackground} from 'hooks/useSceneBackground';
import {useHandleObjectVisibility} from 'hooks/useHandleObjectVisibility';
import Annotations from 'components/Annotations/Annotations';
import {useHandleAnimations} from 'hooks/useHandleAnimations';
import {useGotoState} from 'hooks/useGotoState';
import {invalidate, useThree} from '@react-three/fiber';

const currentGLTFSelector = (state: any) => state.rawGLTF;
const GLTFIsDifferent = (old: GLTF, next: GLTF): boolean => !!next && (old?.scene.uuid === next.scene.uuid);

const Scene: React.FC<{ asset: Asset, regress: boolean }> = ({asset, regress}) => {
  const {scene} = useSceneStore(currentGLTFSelector, GLTFIsDifferent) || {};
  const performance = useThree(state => state.performance);

  useLoadAsset(asset);
  useParseGLTF(scene);
  useSceneBackground();
  useCameraController();
  useHandleObjectVisibility(scene);
  useHandleAnimations();

  // handle clicking on object and going to states
  const handleGotoState = useGotoState();
  const onPointerUp = useCallback((event) => {
    event.stopPropagation();
    handleGotoState(event.object);
  }, [handleGotoState]);

  // TODO useFrame doesn't stop rendering :/
  // useFrame(state => {
  //   state.invalidate(); // otherwise regress is still active
  //   regress && state.performance.regress();
  // });

  // useEffect(() => {
  //   performance.current = regress ? 0.1 : 1;
  //   console.log('performance.current:', performance.current);
  // }, [regress]);


  // RENDER
  if (!scene || !scene.uuid) return null;

  return (
    <>
      <primitive name={scene.name} object={scene} onPointerUp={onPointerUp} />
      <Annotations />

      {/*<axesHelper />*/}
      {/* DEBUG */}
    </>
  );
};

export default Scene;