import React, {useCallback, useEffect} from 'react';
import {useRenderStore} from 'stores/useRenderStore';
import {useIdle} from 'react-use';
import {useFrame, useThree} from '@react-three/fiber';
import {Stats} from '@react-three/drei';

import './WebGLCanvas.scss';

const RenderController: React.FC = () => {
  const {setIsIdle, forceRender, pauseRender} = useRenderStore(state => ({
    setIsIdle: state.setIsIdle,
    forceRender: state.forceRender,
    pauseRender: state.pauseRender
  }));
  const isIdle = useIdle(60_000, false); // TODO IDLE 60s
  const invalidate = useThree(state => state.invalidate);
  const set = useThree(state => state.set);

  const shouldRender = useCallback(() => {
    if ((!isIdle && !pauseRender) || forceRender) {
      set({frameloop: 'demand'});
      invalidate();
    }
    // if (isIdle || pauseRender) {
    //   set({frameloop: 'never'});
    // }
  }, [forceRender, invalidate, isIdle, pauseRender, set]);

  useFrame((state) => {
    shouldRender();
  });

  useEffect(() => {
    setIsIdle(isIdle);
    shouldRender();
  }, [isIdle, setIsIdle, shouldRender]);

  // return null;

  return <Stats
    showPanel={0} // Start-up panel (default=0)
    className="stats" // Optional className to add to the stats container dom element
  />;
};

export default RenderController;