import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as HandTap} from 'assets/icons/hand-tap.svg';
import {useSizeClassStore} from 'stores/useSizeClassStore';

import './TouchToContinue.scss';

const TouchToContinue: React.FC = () => {
  const {t} = useTranslation();
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return (
    <div className="touch-to-continue">
      <HandTap className={`icon ${sizeClass}`} />
      <div className={`text ${sizeClass}`}>
      <Trans
      i18nKey={'landing_page.touch_to_continue'}
      components={{ul: <ul />, li: <li />, img: <img />, hr: <hr />}}
      /></div>
      {/*{t('landing_page.touch_to_continue')*/}
      {/*  .split('\n')*/}
      {/*  .map(line => <div key={line} >{line}</div>)} /!* TODO key ??*!/*/}
    </div>
  );
};

export default TouchToContinue;