import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSizeClassStore} from 'stores/useSizeClassStore';

import './MenuHeaderFooter.scss';
import logo_1024x512 from 'assets/images/highspeed_line_logo_1024.png';
import logo_ceva from 'assets/images/logo_ceva.png';

export const MenuHeader: React.FC = () => {
  const {t} = useTranslation();
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return (
    <>
      <div className="flex-2">
        <div className="menu-logo-page">
          <img className={sizeClass} src={logo_1024x512} alt="logo" />
        </div>
      </div>
      <div className="flex-1">
        <div className={`welcome-message ${sizeClass}`}>
          {t('landing_page.welcome_message')}
        </div>
      </div>
    </>
  );
};

export const MenuFooter: React.FC = () => {
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return (
    <div className="flex-1">
      <div className="menu-logo-ceva">
        <img className={sizeClass} src={logo_ceva} alt="logo" />
      </div>
    </div>
  );
};
