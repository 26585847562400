import React, {useCallback, useEffect, useRef} from 'react';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import LoadingFeedback from 'components/LandingPage/LoadingFeedback';
import LoadingError from 'components/LandingPage/LoadingError';
import TouchToContinue from 'components/LandingPage/TouchToContinue';
import {MenuFooter, MenuHeader} from 'components/Shared/MenuHeaderFooter';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

const LandingPage: React.FC = () => {
  const landingPageRef = useRef<HTMLDivElement>(null);
  const {currentFSMState, setFSMState} = useFSMStore(state => ({
    currentFSMState: state.currentFSMState,
    setFSMState: state.setFSMState
  }));

  useEffect(() => {
    if (currentFSMState === FSMStates.loaded)
      addClass(landingPageRef, 'semi-transparent');
  }, [currentFSMState]);

  // When loading is complete and clicked
  const hideLandingPage = useCallback(() => {
    if (currentFSMState === FSMStates.loaded)
      setFSMState(FSMStates.mainMenu);
  }, [currentFSMState, setFSMState]);

  // If we're not loading or right after loading then hide this component
  if (currentFSMState !== FSMStates.loading && currentFSMState !== FSMStates.loaded && currentFSMState !== FSMStates.loadingError) return null;

  return (
    <div ref={landingPageRef} id="landingPage" className="full-page flex-center" onClick={hideLandingPage}>
      <MenuHeader />

      <div className="flex-2">
        {currentFSMState === FSMStates.loading && <LoadingFeedback />}
        {currentFSMState === FSMStates.loadingError && <LoadingError />}
        {currentFSMState === FSMStates.loaded && <TouchToContinue />}
      </div>

      <MenuFooter/>
    </div>
  );
};

export default LandingPage;
