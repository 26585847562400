import React, {useEffect, useRef} from 'react';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import {useRenderStore} from 'stores/useRenderStore';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

const Video: React.FC = () => {
  const videoPageRef = useRef<HTMLDivElement>(null);
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  const setPauseRender = useRenderStore(state => state.setPauseRender);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (currentFSMState === FSMStates.discover) {
      setPauseRender(true);
      removeClass(videoPageRef, 'hidden');
    } else {
      addClass(videoPageRef, 'hidden');
      setPauseRender(false);
    }
  }, [currentFSMState, setPauseRender]);

  const getPlayerSize = () => {
    const size = {width: 560, height: 315};
    const aspect = 1.7777778;
    const toWidth = aspect;
    const toHeight = 1 / aspect;

    // page dimensions
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    const maxHeight = pageHeight * 0.66;
    const maxWidth = pageWidth * 0.66;

    if (maxHeight * toWidth >= maxWidth) {
      size.width = maxWidth;
      size.height = maxWidth * toHeight;
    } else if (maxWidth * toHeight > maxHeight) {
      size.height = maxHeight;
      size.width = maxHeight * toWidth;
    }

    return size;
  };

  if (currentFSMState !== FSMStates.discover) return null;

  return (
    <div ref={videoPageRef} className="full-page flex-center semi-transparent hidden">
      {/*<iframe src="https://www.youtube.com/embed/SB-qEYVdvXA"*/}
      {/*        {...getPlayerSize()}*/}
      {/*        title="YouTube video player"*/}
      {/*        frameBorder="0"*/}
      {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
      {/*        allowFullScreen />*/}

      <video {...getPlayerSize()} controls autoPlay loop>
        <source src="assets/videos/Tribia_-_Ceva_Fastrack_08.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </div>
  );
};

export default Video;