import {useFSMStore} from 'stores/useFSMStore';
import {useEffect} from 'react';
import {useSceneStore} from 'stores/useSceneStore';
import {logHelper, tLogStyled} from 'utils/Logger';
import {useRenderStore} from 'stores/useRenderStore';

export const useHandleAnimations = (): void => {
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  const actions = useSceneStore(state => state.actions);
  const isIdle = useRenderStore(state => state.isIdle);

  useEffect(() => {
    if (currentFSMState) {

      const isValidRecursive = (validStates: string, currentFSMState: string): boolean => {
        if (!validStates || !currentFSMState) return false;

        if (validStates.includes(currentFSMState)) return true;
        else {
          const parent = currentFSMState.split('.').slice(0, -1); // remove last element
          if (parent.length > 0) return isValidRecursive(validStates, parent.join('.'));
        }
        return false;
      };

      Object.keys(actions).filter(key => !!actions[key]).forEach(key => {
        if (isValidRecursive(key, currentFSMState)) {
          actions[key]!.play();
          // actions[key]!.timeScale = 0.25;
          tLogStyled(`[useHandleAnimations] Play action ${key}`, logHelper.start); // DEBUG
        } else {
          actions[key]?.stop();
          tLogStyled(`[useHandleAnimations] Stop action ${key}`, logHelper.stop); // DEBUG
        }
      });
    }
  }, [currentFSMState, actions]);

  useEffect(() => {
    Object.keys(actions).filter(key => !!actions[key]).forEach(key => {
      actions[key]!.timeScale = isIdle ? 0 : 1;
    });
  }, [actions, isIdle]);
};
