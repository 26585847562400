import React, {useCallback, useEffect, useRef, useState} from 'react';
import {NavigationButton, NavigationButtonContainer} from 'components/Navigation/NavigationButton';
import returnArrow from 'assets/icons/return-arrow-medium.svg';
import {FSMStates} from 'types/FSMStates';
import {useFSMStore} from 'stores/useFSMStore';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

import './Navigation.scss';

const Navigation: React.FC = () => {
  const navigationContainerRef = useRef<HTMLDivElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const {currentFSMState, currentFSMStateSiblings, setFSMState, setPreviousFSMState} = useFSMStore(state => ({
    currentFSMState: state.currentFSMState,
    currentFSMStateSiblings: state.currentFSMStateSiblings,
    setFSMState: state.setFSMState,
    setPreviousFSMState: state.setPreviousFSMState
  }));
  const [splitStates, setSplitStates] = useState<string[]>([]);

  // TODO DEBUG REMOVE BELOW
  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      e.key === 'Escape' && setFSMState(FSMStates.mainMenu);
    };
    window.addEventListener('keyup', callback);

    return () => window.removeEventListener('keyup', callback);
  }, []);
  // TODO DEBUG REMOVE ABOVE

  // State Management
  useEffect(() => {
    // Navigation visibility
    if (currentFSMState === FSMStates.loading ||
      currentFSMState === FSMStates.loaded ||
      currentFSMState === FSMStates.mainMenu) {
      addClass(navigationContainerRef, 'hidden');
    } else {
      removeClass(navigationContainerRef, 'hidden');
    }

    const _splitStates = currentFSMState?.split('.') || [];
    setSplitStates(_splitStates);
    if (_splitStates.length > 2) { // length > 2 because _splitStates[0] = process, _splitStates[1] = zoom we want [2+]
      removeClass(backButtonRef, 'hidden');
    } else {
      addClass(backButtonRef, 'hidden');
    }

    // Button visibility
    // TODO: display next button if current state has siblings
    //  and rotate between siblings on click

  }, [currentFSMState]);


  const handlePrev = useCallback(() => {

    // will set 'process.default'
    if (splitStates.length > 3) setPreviousFSMState();
    else setFSMState([splitStates[0], 'default'].join('.')); // TODO UGLY but it works for now


  }, [splitStates]);

  const handleMenu = useCallback(() => {
    setFSMState(FSMStates.mainMenu);
  }, [setFSMState]);

  const handleNext = useCallback(() => {
    console.log('click next');

    if (currentFSMState) {
      const currentStateIndex = currentFSMStateSiblings.indexOf(currentFSMState);
      const nextStateIndex = (currentStateIndex + 1) % currentFSMStateSiblings.length;
      setFSMState(currentFSMStateSiblings[nextStateIndex]);
    }
  }, [currentFSMState, currentFSMStateSiblings, setFSMState]);

  return (
    <div ref={navigationContainerRef} className="full-page navigation transparent">
      <NavigationButtonContainer>
        <NavigationButton ref={backButtonRef} caption={<img src={returnArrow} alt={'go back'} />} onClick={handlePrev} /* classNames={'hidden'}*/ />
        <NavigationButton caption={'MENU'} onClick={handleMenu} classNames={''} />
        <NavigationButton caption={'NEXT'} onClick={handleNext} classNames={currentFSMStateSiblings.length > 1 ? '' : 'hidden'} />
      </NavigationButtonContainer>
    </div>
  );
};

export default Navigation;
