import React from 'react';
import {useSizeClassStore} from 'stores/useSizeClassStore';

import './Navigation.scss';

export const NavigationButtonContainer: React.FC = (props) => {
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return <div className={`navigation-button-container ${sizeClass}`}>{props.children}</div>;
};

type NavigationButtonProps = {
  caption?: string | JSX.Element;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  classNames?: string;
}

// export const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
// eslint-disable-next-line react/display-name
export const NavigationButton = React.forwardRef<HTMLButtonElement, NavigationButtonProps>((props, ref) => {
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return (
    <button
      ref={ref}
      onClick={props.onClick}
      className={`navigation-button ${props.classNames} ${sizeClass}`}
    >
      {props.caption}
    </button>
  );
});
