import {useLoader, useThree} from '@react-three/fiber';
import {TextureLoader} from 'three';
import backgroundImage from 'assets/images/background_light.jpg';
import {useEffect} from 'react';

export const useSceneBackground = (): void => {
  const backgroundTexture = useLoader(TextureLoader, backgroundImage);
  const scene = useThree(state => state.scene);

  useEffect(() => {
    if (scene && backgroundTexture) {
      scene.background = backgroundTexture;
    }
  }, [backgroundTexture, scene]);
};
