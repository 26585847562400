import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSizeClass} from 'hooks/useSizeClass';

import DebugOverlay from 'components/Debug/DebugOverlay';
import LandingPage from 'components/LandingPage/LandingPage';
import MainMenu from 'components/MainMenu/MainMenu';
import Logo from 'components/Logo/Logo';
import Navigation from 'components/Navigation/Navigation';
import WebGLCanvas from 'components/WebGL/WebGLCanvas';

import './App.scss';
import Video from 'components/Video/Video';
import Description from 'components/Description/Description';

const App: React.FC = () => {
  const appRef = useSizeClass();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = t('page.title');
  }, [t]);

  return (
    <div ref={appRef} className="app">
      {/*<DebugOverlay />*/}
      <LandingPage />
      <MainMenu />
      <Video />
      <Description />

      <Logo />
      <Navigation />

      <WebGLCanvas />
    </div>
  );
};

export default App;
