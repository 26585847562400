import React, {useCallback, useEffect, useRef} from 'react';
import {MenuFooter, MenuHeader} from 'components/Shared/MenuHeaderFooter';
import {MenuButton, MenuButtonContainer} from 'components/MainMenu/MenuButton';
import {addClass, removeClass} from 'utils/HtmlClassUtils';
import {useTranslation} from 'react-i18next';
import {useSizeClassStore} from 'stores/useSizeClassStore';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';

const LandingPage: React.FC = () => {
  const {t} = useTranslation();
  const mainMenuPageRef = useRef<HTMLDivElement>(null);
  const {currentFSMState, setFSMState} = useFSMStore(state => ({
    currentFSMState: state.currentFSMState,
    setFSMState: state.setFSMState
  }));

  // TODO: menu buttons
  const gotoDiscover = useCallback(() => setFSMState(FSMStates.discover), [setFSMState]);
  const gotoProcess = useCallback(() => setFSMState(FSMStates.process.default), [setFSMState]);
  const goToDatasheet = useCallback(() => setFSMState(FSMStates.datasheet.default), [setFSMState]);

  // When loading is complete and user touched the screen
  useEffect(() => {
    if (currentFSMState !== FSMStates.loading && currentFSMState !== FSMStates.loaded) {
      if (currentFSMState === FSMStates.mainMenu) {
        removeClass(mainMenuPageRef, 'hidden');
      } else {
        addClass(mainMenuPageRef, 'hidden');
      }
    }
  }, [currentFSMState]);

  if (currentFSMState === FSMStates.loading || currentFSMState === FSMStates.loaded) return null;

  return (
    <div ref={mainMenuPageRef} id="mainMenu" className="full-page flex-center semi-transparent">
      <MenuHeader />

      <MenuButtonContainer>
        <MenuButton caption={t('main_menu.buttons.discover')} onClick={gotoDiscover} delay={0} />
        <MenuButton caption={t('main_menu.buttons.process')} onClick={gotoProcess} delay={100} />
        <MenuButton caption={t('main_menu.buttons.datasheet')} onClick={goToDatasheet} delay={200} />
      </MenuButtonContainer>

      <MenuFooter />
    </div>
  );
};

export default LandingPage;
