import React, {useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useFSMStore} from 'stores/useFSMStore';
import {useSizeClassStore} from 'stores/useSizeClassStore';
// import {addClass, removeClass} from 'utils/HtmlClassUtils';
import './Description.scss';

const Description: React.FC = () => {
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  const sizeClass = useSizeClassStore(state => state.sizeClass);
  const {t} = useTranslation();
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const title = t(currentFSMState + '.title');
  const description = t(currentFSMState + '.description');

  // useEffect(() => {
  //   (title === '') ? addClass(titleRef, 'hidden') : removeClass(titleRef, 'hidden');
  //   (description === '') ? addClass(descriptionRef, 'hidden') : removeClass(descriptionRef, 'hidden');
  // }, [title, description]);

  if (!currentFSMState) return null;

  return (
    <div className="full-page transparent description">
      {title && <div ref={titleRef} className={`title ${sizeClass}`}>{title}</div>}
      {description && <div ref={descriptionRef} className={`description ${sizeClass}`}>
        <div className="description-title">{title}</div>
        <Trans
          i18nKey={currentFSMState + '.description'}
          components={{ul: <ul />, li: <li />, img: <img />, hr: <hr />}}
        />
      </div>}
    </div>
  );
};

export default Description;