import React, {useEffect, useRef} from 'react';
import {useSizeClassStore} from 'stores/useSizeClassStore';

import './MenuButton.scss';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

export const MenuButtonContainer: React.FC = (props) => {
  const sizeClass = useSizeClassStore(state => state.sizeClass);

  return <div className={`flex-2 menu-button-container ${sizeClass}`}>{props.children}</div>;
};


type MenuButtonProps = {
  caption?: string,
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void,
  delay?: number
}

export const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const sizeClass = useSizeClassStore(state => state.sizeClass);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => removeClass(buttonRef, 'offscreen-bottom'), props.delay);
    return () => clearTimeout(timeout);
  }, [sizeClass, props.delay]);

  return (
    <button
      ref={buttonRef}
      onClick={props.onClick}
      className={`menu-button ${sizeClass} offscreen-bottom`}
    >
      {props.caption}
    </button>
  );
};
