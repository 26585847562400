import {useEffect} from 'react';
import {useGLTF} from 'hooks/useGLTF';
import {useSceneStore} from 'stores/useSceneStore';
import {Asset} from 'stores/useConfigStore';
import {useFSMStore} from 'stores/useFSMStore';
import {FSMStates} from 'types/FSMStates';
import {Object3D} from 'three/src/core/Object3D';
import {logHelper, tLog} from 'utils/Logger';
import {useAnimations} from '@react-three/drei';

export const useLoadAsset = (asset: Asset): void => {

  const {setCurrentGLTF, setAnimations, setActions, setIsLoading, setLoadedBytes, resetLoadedBytes} = useSceneStore(state => ({
    setCurrentGLTF: state.setCurrentGLTF,
    setAnimations: state.setAnimations,
    setActions: state.setActions,
    setIsLoading: state.setIsLoading,
    setLoadedBytes: state.setLoadedBytes,
    resetLoadedBytes: state.resetLoadedBytes
  }));

  const setFSMState = useFSMStore(state => state.setFSMState);

  // Prevent from displaying 100% before loading actually begin
  useEffect(() => {
    resetLoadedBytes();
    setIsLoading(true);
    setFSMState(FSMStates.loading);
  }, [asset.uri, resetLoadedBytes, setFSMState, setIsLoading]);

  const gltfObject = useGLTF(asset.uri, true, true, e => setLoadedBytes(e.loaded, asset.filesize));
  const {actions} = useAnimations(gltfObject.animations, gltfObject.scene);

  // When scene changes, store it
  useEffect(() => {
    if (gltfObject?.scene) {

      parseObjectTags(gltfObject.scene);
      setAnimations(gltfObject.animations);
      setActions(actions);
      setCurrentGLTF(gltfObject);

      setIsLoading(false); // TODO: move where parsing is done
      // setFSMState(FSMStates.loaded); // TODO: move where parsing is done
    }
  }, [gltfObject, setCurrentGLTF, setIsLoading]);
};

/**
 * Parse JSON properties to Object properties directly usable
 * @param scene
 */
const parseObjectTags = (scene: Object3D) => {
  tLog('%c[useLoadAssets] \u27f6 Parsing Scene Objects\' tags', logHelper.loading);
  scene.traverse((obj: Object3D) => {
    try {
      // from json text to js objects
      obj.userData.tags = JSON.parse(obj.userData.tags);
    } catch (e) {/* noop */
    }
  });
  return scene;
};
