import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {Object3D} from 'three/src/core/Object3D';
import {useCameraStore} from 'stores/useCameraStore';
import {Html} from '@react-three/drei/web/Html';
import {useOutlineStore} from 'stores/useOutlineStore';

import './Annotations.scss';
import {isValidTypes} from 'hooks/useActiveObject';
import {useAnnotationPositionning} from 'hooks/useAnnotationPositionning';
import {useFSMStore} from 'stores/useFSMStore';
import {Box3, Vector3} from 'three';
import {useSizeClassStore} from 'stores/useSizeClassStore';

type AnnotationProps = {
  object: Object3D
  expandedUuidStateArray: [string, Dispatch<SetStateAction<string>>]
}

const Annotation: React.FC<AnnotationProps> = ({object, expandedUuidStateArray}) => {
  const [expandedUuid, setExpandedUuid] = expandedUuidStateArray;
  const isPointerDown = useRef<boolean>(false);

  const sizeClass = useSizeClassStore(state => state.sizeClass);

  const [position, setPosition] = useState<Vector3>(new Vector3(0, 0, 0));
  const [isExpanded, setIsExpandedState] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [outlinable, setOutlinable] = useState<boolean>();
  const [gotoState, setGotoState] = useState<string | null>(null);
  const [forcedAngle, setForcedAngle] = useState<number | null>(-Math.PI / 2); // "top" instead of null by default
  const [distanceMultiplier] = useState<number | null>(object.userData.tags?.annotationDistanceMultiplier); // "top" instead of null by default

  const [annotationRef, lineRef, overrideCalculatePosition] = useAnnotationPositionning(forcedAngle, distanceMultiplier);

  const objectRef = useRef<Object3D>(object);

  const cameraBrain = useCameraStore(state => state.cameraBrain);
  const setFSMState = useFSMStore(state => state.setFSMState);

  // Init Annotation title & description
  useEffect(() => {
    const {tags} = object.userData;
    if (cameraBrain && isValidTypes(tags?.type, 'annotation')) {
      setTitle(tags.annotationTitle);
      setDescription(tags.annotationDescription);
      setOutlinable(tags.annotationOutlineSelected);

      switch (tags.annotationForcePosition) {
        case 'topLeft':
          setForcedAngle(-3 * Math.PI / 4);
          break;
        case 'top':
          setForcedAngle(-Math.PI / 2);
          break;
        case 'topRight':
          setForcedAngle(-Math.PI / 4);
          break;
        case 'right':
          setForcedAngle(0);
          break;
        case 'bottomRight':
          setForcedAngle(Math.PI / 4);
          break;
        case 'bottom':
          setForcedAngle(Math.PI / 2);
          break;
        case 'bottomLeft':
          setForcedAngle(3 * Math.PI / 4);
          break;
        case 'left':
          setForcedAngle(Math.PI);
          break;
        default:
          setForcedAngle(null);
      }
    }

    if (isValidTypes(tags?.type, 'gotoState')) {
      setGotoState(tags.gotoState);
    }
  }, [cameraBrain, object]);

  // expand description when own uuid is set in parent component
  useEffect(() => {
    setIsExpandedState(expandedUuid === object.uuid && !!description);
  }, [object.uuid, expandedUuid, description]);

  const onPointerDown = useCallback(() => isPointerDown.current = true, []);

  // sending current uuid (or empty string if already expanded) to parent component
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;

    // EXPAND
    setExpandedUuid(previous => {
      const {uuid} = object;
      const doExpand = previous !== uuid;
      if (doExpand) {
        if (outlinable) {
          const objArray: any[] = [];
          // @ts-ignore
          object.traverse(obj => obj.isMesh && objArray.push({current: obj})); // TODO extract when object changes
          useOutlineStore.setState({selectedObjects: objArray});
        }
        return uuid;
      } else {
        if (outlinable) useOutlineStore.setState({selectedObjects: []});
        return '';
      }
    });

    // GOTO STATE
    if (gotoState !== null) setFSMState(gotoState);
    // TODO display back button!

    isPointerDown.current = false;

  }, [gotoState, object, outlinable, setExpandedUuid, setFSMState]);

  useEffect(() => {
    // @ts-ignore
    if (object.isMesh) { // get bounding box center
      const boundingBox = new Box3();
      boundingBox.setFromObject(object);
      setPosition(boundingBox.getCenter(new Vector3()));
    } else { // get pivot position
      setPosition(object.getWorldPosition(new Vector3()));
    }

  }, [object]);

  if (!cameraBrain) return null;

  return (
    <Html
      zIndexRange={isExpanded ? [502, 501] : [500, 100]} // need component to update when changing zIndexRange
      style={{pointerEvents: 'none'}}
      position={[position.x, position.y, position.z]}
      calculatePosition={overrideCalculatePosition}
    >
      <div className="annotation-container">

        <svg className={`annotation-circle ${sizeClass}`}>
          <circle cx="1rem" cy="1rem" r=".9rem" stroke="black" strokeWidth="1.5px" fill="transparent" />
        </svg>

        <svg className="annotation-line">
          <line ref={lineRef} stroke="black" strokeWidth="1.5px" />
        </svg>

        <div ref={annotationRef} className={`annotation ${sizeClass}`} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
          <div className={`title ${sizeClass}`}>{title}</div>
          <div className={`description ${isExpanded ? 'expanded' : ''} ${sizeClass}`}>{description}</div>
        </div>

      </div>
    </Html>
  );
};

Annotation.whyDidYouRender = true; // TODO REMOVE DEBUG

export default Annotation;
