import React, {useEffect, useState} from 'react';
import {useFSMStore} from 'stores/useFSMStore';
import {useSceneStore} from 'stores/useSceneStore';
import Annotation from 'components/Annotations/Annotation';
import {useAnnotationActiveState} from 'hooks/useActiveObject';
import {useOutlineStore} from 'stores/useOutlineStore';

import './Annotations.scss';

const Annotations: React.FC = () => {
  const allAnnotationObjects = useSceneStore(state => state.sortedObjects['annotation']);
  const annotationObjects = useAnnotationActiveState(allAnnotationObjects); // TODO cause rerender because [] !== [] by ref
  const expandedUuidStateArray = useState<string>(''); // single source of truth for active Annotation...
  const [, setExpandedState] = expandedUuidStateArray; // keep both array and setter

  // Reset selected annotation on FSMState change
  const currentFSMState = useFSMStore(state => state.currentFSMState);
  useEffect(() => {
    setExpandedState('');
    useOutlineStore.setState({selectedObjects: []});
  }, [currentFSMState, setExpandedState]);

  useEffect(() => {
    // console.log(allAnnotationObjects, annotationObjects);
  }, [allAnnotationObjects, annotationObjects]);

  return (
    <>
      {annotationObjects?.map(annotation =>
        <Annotation key={annotation.uuid} object={annotation} expandedUuidStateArray={expandedUuidStateArray} />)}
    </>
  );
};

// Annotations.whyDidYouRender = true; // TODO REMOVE DEBUG

export default Annotations;
