// TODO: update regularly from ceva-fastrack-webgl project...

export const FSMStates = {
  loading: 'loading',
  loaded: 'loaded',
  loadingError: 'loadingError',
  mainMenu: 'mainMenu',
  discover: 'discover',
  process: {
    default: 'process.default',
    zoom: {
      sorting: 'process.zoom.sorting',
      dovac: 'process.zoom.dovac',
      desvac: 'process.zoom.desvac',
      // stacking: 'process.zoom.stacking',
    }
  },
  datasheet: {
    default: 'datasheet.default',
    zoom: {
      separationAndSorting: 'datasheet.zoom.separationAndSorting',
      dovac: 'datasheet.zoom.dovac',
      desvac_wc: 'datasheet.zoom.desvac_wc',
      desvac_wc_cp: 'datasheet.zoom.desvac_wc_cp',
      desvac_gel: 'datasheet.zoom.desvac_gel',
      desvac_spray: 'datasheet.zoom.desvac_spray',
    }
  }
};
